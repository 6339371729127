import { format } from "../../../../../../js/lib/Date";
import { createLoader } from "../../../../../../js/react/components/general/notifications";
import axios from "axios";
import PropTypes from "prop-types";
import { useCallback, useContext, useState } from "react";
import { uctrans } from "../../../../../../js/lib/Translator";
import DeleteDialog from "../../../../../../js/react/components/general/DeleteDialog";
import EvaIcon from "../../../../../../js/react/components/general/EvaIcon";
import MediaLibrary from "./MediaLibrary";
import MediaLibraryContext from "./MediaLibraryContext";
import MediaLibraryMediaDialog from "./MediaLibraryMediaDialog";
import { route } from "../../../../../../js/helpers";

export default function MediaLibraryDocument({ document }) {
	const { mode, refreshMedia, selected, onMediaClick, getMarketingThemes } = useContext(MediaLibraryContext);

	const [editing, setEditing] = useState(false);
	const [deleteWarnings, setDeleteWarnings] = useState(null);
	const [deleting, setDeleting] = useState(false);

	const startDelete = useCallback(async () => {
		if (deleteWarnings === null) {
			// Retrieve warnings
			try {
				const response = await axios.get(route(`crm.document.delete-check`), { params: { ids: [document.id] } });
				setDeleteWarnings(response.data);
			} catch (error) {
				console.error(error);
				setDeleteWarnings([]);
			}
		}

		setDeleting(true);
	}, [document.id, deleteWarnings, setDeleteWarnings, setDeleting]);

	const handleDelete = useCallback(async () => {
		setDeleting(false);
		const loader = createLoader(uctrans("general.:item_is_being_deleted", {}, { item: "media.document.singular" }));
		try {
			await axios.delete(route(`crm.document.destroy`, document.id));
			loader.success(uctrans("general.deleted_:item", {}, { item: "media.document.singular" }));
			refreshMedia();
		} catch (error) {
			console.error(error);
			loader.failure(error.message);
		}
	}, [document.id, refreshMedia]);

	const handleEditOpen = async () => {
		await getMarketingThemes();
		setEditing(true);
	};

	return (
		<div className="p-2 border-b flex">
			<span className="flex-grow cursor-pointer" onClick={() => onMediaClick(document)}>
				{selected.indexOf(document.id) !== -1 && (
					<EvaIcon className="text-secondary fill-current" type="checkmark" width="16" height="16" />
				)}
				{!document?.file?.public && <EvaIcon className="fill-current" type="lock-outline" width="16" height="16" />}
				{document.name}
			</span>
			<span className="flex mr-4">{format(document.created_at, "dd-MM-Y")}</span>
			{mode === MediaLibrary.MODE_LIBRARY && (
				<>
					<a href={document.download_url} target="_blank" className="flex-shrink cursor-pointer" rel="noreferrer">
						<EvaIcon type="download-outline" width="16" height="16" />
					</a>
					<div className="flex-shrink mr-1 cursor-pointer" onClick={handleEditOpen}>
						<EvaIcon type="edit-outline" width="16" height="16" />
					</div>
					<div className="flex-shrink cursor-pointer" onClick={startDelete}>
						<EvaIcon type="trash-2-outline" width="16" height="16" />
					</div>

					<MediaLibraryMediaDialog
						isOpen={editing}
						media={document}
						isDocument
						onCancel={() => setEditing(false)}
						onConfirm={() => {
							setEditing(false);
							refreshMedia();
						}}
					/>

					<DeleteDialog
						isOpen={deleting}
						onCancel={() => setDeleting(false)}
						onConfirm={handleDelete}
						deleteData={{
							message: uctrans(
								"general.confirm_delete_:item_:label",
								{ label: document.name },
								{ item: "media.document.singular" },
							),
							errors: [],
							warnings: [],
						}}
					/>
				</>
			)}
		</div>
	);
}

MediaLibraryDocument.propTypes = {
	document: PropTypes.object.isRequired,
};
