import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { format, parse } from "../../../../../js/lib/Date";
import { trans, uctrans } from "../../../../../js/lib/Translator";
import DatePicker from "../../../../../js/react/components/general/DatePicker";
import Checkmark from "../../../../../js/react/components/general/form/checkmark/Checkmark";
import LabeledTextarea from "../../../../../js/react/components/general/form/LabeledTextarea";
import LabeledTextInput from "../../../../../js/react/components/general/form/LabeledTextInput";
import Radio from "../../../../../js/react/components/general/form/radio/Radio";
import RadioGroup from "../../../../../js/react/components/general/form/radio/RadioGroup";
import FormDivider from "../../../../../js/react/components/general/FormDivider";
import Select from "../../../../../js/react/components/general/Select";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import cloneDeep from "lodash/cloneDeep";
import map from "lodash/map";
import find from "lodash/find";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import { route, can } from "../../../../../js/helpers";
import Nav from "../../../../../js/lib/Nav";
import Url from "../cms/general/attributes/Url";
import FileInput from "../forms/FileInput";
import TextEditor from "../forms/textEditor/TextEditor";
import CollapsibleContent from "../general/CollapsibleContent";
import CopyImageSelector from "../media/selector/CopyImageSelector";
import ClientTreatmentDividationsSum from "./ClientTreatmentDividationsSum";
import ClientTreatmentInvitersSelector from "./ClientTreatmentInvitersSelector";
import ClientTreatmentLocations from "./ClientTreatmentLocations";
import Navigation from "./Navigation";

export default function ClientTreatmentForm(props) {
	const creating = window.data.client_treatment_form_data.id === -1;

	const [formData, setFormData] = useState(
		typeof props.ClietTreatmentFormData === "undefined"
			? cloneDeep(window.data.client_treatment_form_data)
			: props.ClietTreatmentFormData,
	);

	const [staticData, setStaticData] = useState(
		typeof props.ClietTreatmentStaticData === "undefined"
			? cloneDeep(window.data.client_treatment_static_data)
			: props.ClietTreatmentStaticData,
	);

	useEffect(() => {
		if (typeof props.ClietTreatmentFormData !== "undefined") {
			setFormData(props.ClietTreatmentFormData);
		}
	}, [props.ClietTreatmentFormData]);

	useEffect(() => {
		if (typeof props.ClietTreatmentStaticData !== "undefined") {
			setStaticData(props.ClietTreatmentStaticData);
		}
	}, [props.ClietTreatmentStaticData]);

	const [currentType, setCurrentType] = useState(Number(formData.type));

	const handleFormValueChange = (key, value) => {
		const newFormData = { ...formData };
		newFormData[key] = value;
		setFormData(newFormData);
	};

	useEffect(() => {
		if (`${formData.type}` === `${staticData.client_treatment_types.PRODUCT.key}`) {
			handleFormValueChange("self_enrollment", false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formData.type, staticData.client_treatment_types.PRODUCT.key]);

	const onSuccess = () => {
		if (creating) {
			Nav.go(route("crm.client-treatments.index"));
		} else {
			window.location.reload();
		}
	};

	const submitURL = () => {
		if (!creating) {
			return route("crm.client-treatments.update", formData.id);
		} else if (creating) {
			return route("crm.client-treatments.store");
		}
	};

	const previousURL = () => route("crm.client-treatments.index");

	const participantStatuses = map(staticData.client_treatment_post_statuses, status => ({
		value: status.value,
		label: status.label,
	}));

	const currentSelectedEventType = find(
		staticData.client_treatment_types,
		type => `${type.key}` === `${formData.type}`,
	);
	const landingPageAvailable = currentSelectedEventType ? currentSelectedEventType.landingpagePossible : false;

	return (
		<>
			{!creating && (
				<Navigation
					selectedTab="client-treatment"
					clientTreatment={formData}
					canUpdate={can("client_treatment.client_treatment", "update")}
					clientTreatmentTypes={staticData.client_treatment_types}
					loggedInInviter={
						window.data.user
							? formData.inviters.find(inviter => Number(inviter.user_id) === Number(window.data.user.id))
							: null
					}
				/>
			)}
			<div className="page-title-container">
				<h1 className="page-title">{uctrans("client_treatment.singular")}</h1>

				<a className="link" href={previousURL()}>
					{uctrans("general.back_to_overview_:page", {}, { page: "client_treatment.plural" })}
				</a>
			</div>

			<AjaxForm
				method={creating ? "POST" : "PUT"}
				submitUrl={submitURL()}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "client_treatment.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "client_treatment.singular" })}
				data={{ ...formData }}
				onSuccess={onSuccess}
				useFlashMessage>
				<div className="min-h-screen md:flex">
					<div className="flex-1 w-2/3 mr-12">
						<div className="form-container">
							<div className="form-1-2">
								<label htmlFor="organizing_user_id">
									{uctrans("client_treatment.fields.organisator")} <span className="text-orange"> *</span>
								</label>
								<Select
									isClearable
									value={staticData.organisators.filter(
										({ value }) => Number(value) === Number(formData.organizing_user_id),
									)}
									options={staticData.organisators}
									name="organizing_user_id"
									onChange={selectedValue =>
										handleFormValueChange("organizing_user_id", selectedValue ? selectedValue.value : null)
									}
									placeholder={uctrans("general.select_:item", {}, { item: "client_treatment.fields.organisator" })}
									disabled={!can("client_treatment.client_treatment", "update")}
								/>
							</div>

							<div className="form-1-2">
								<label htmlFor="type">
									{uctrans("client_treatment.fields.type")}
									<span className="text-orange"> *</span>
								</label>

								<Select
									value={staticData.client_treatment_event_types.filter(
										({ value }) => Number(value) === Number(formData.type),
									)}
									options={staticData.client_treatment_event_types}
									name="type"
									isClearable={false}
									onChange={selectedValue => {
										handleFormValueChange("type", selectedValue ? selectedValue.value : null);
										setCurrentType(selectedValue ? selectedValue.value : null);
									}}
									placeholder={uctrans("general.select_:item", {}, { item: "client_treatment.fields.type" })}
									disabled={!can("client_treatment.client_treatment", "update")}
								/>
							</div>

							<div className="form-1-2">
								<LabeledTextInput
									name="name"
									value={formData.name}
									onChange={e => handleFormValueChange(e.target.name, e.target.value)}
									label="client_treatment.fields.name"
									required
									disabled={!can("client_treatment.client_treatment", "update")}
								/>
							</div>

							{currentType === staticData.client_treatment_types.WEBINAR.key ? (
								<div className="form-1-2">
									<Url
										label={uctrans("client_treatment.fields.webinar_url")}
										value={formData.webinar_url}
										onChange={value => {
											handleFormValueChange("webinar_url", value);
										}}
										disabled={!can("client_treatment.client_treatment", "update")}
									/>
								</div>
							) : (
								<div className="form-1-2">
									<LabeledTextInput
										name="location"
										value={formData.location}
										onChange={e => handleFormValueChange(e.target.name, e.target.value)}
										label="client_treatment.fields.location"
										disabled={!can("client_treatment.client_treatment", "update")}
									/>
								</div>
							)}

							<div className="form-1-2">
								<label htmlFor="start">{uctrans("client_treatment.fields.start")}</label>
								<DatePicker
									initialValue={formData.start ? parse(formData.start) : null}
									onChange={date => handleFormValueChange("start", date ? format(date) : null)}
									showTimeSelect
									disabled={!can("client_treatment.client_treatment", "update")}
								/>
							</div>

							<div className="form-1-2">
								<label htmlFor="end">
									{uctrans("client_treatment.fields.end")}
									<span className="text-orange"> *</span>
								</label>
								<DatePicker
									initialValue={formData.end ? parse(formData.end) : null}
									onChange={date => handleFormValueChange("end", date ? format(date) : null)}
									showTimeSelect
									disabled={!can("client_treatment.client_treatment", "update")}
								/>
							</div>

							<div className="form-1-2">
								<label htmlFor="active">{uctrans("client_treatment.fields.active")}</label>
								<RadioGroup
									name="active"
									horizontal
									disabled={!can("client_treatment.client_treatment", "update")}
									value={formData.active}
									onChange={value => handleFormValueChange("active", value)}>
									<Radio label={uctrans("general.yes")} value={1} />
									<Radio label={uctrans("general.no")} value={0} />
								</RadioGroup>
							</div>

							{formData.usefull_content_character !== null && !creating && (
								<div className="form-1-2">
									<label htmlFor="usefull_content_character">
										{uctrans("client_treatment.fields.usefull_content_character")}
										<span className="text-orange"> *</span>
									</label>

									<Select
										value={staticData.usefull_content_characters.filter(
											({ value }) =>
												formData.usefull_content_character !== null &&
												Number(value) === Number(formData.usefull_content_character),
										)}
										options={staticData.usefull_content_characters}
										name="usefull_content_character"
										isClearable={false}
										onChange={selectedValue =>
											handleFormValueChange("usefull_content_character", selectedValue ? selectedValue.value : null)
										}
										placeholder={uctrans(
											"general.select_:item",
											{},
											{ item: "client_treatment.fields.usefull_content_character" },
										)}
										disabled
									/>
								</div>
							)}

							<div className="form-full">
								<div className="form-full">
									<LabeledTextarea
										label="client_treatment.fields.description"
										id="description"
										name="description"
										value={formData.description}
										onChange={e => handleFormValueChange(e.target.name, e.target.value)}
									/>
								</div>
							</div>

							<div className="form-full">
								<div className="form-1-2">
									<label htmlFor="organizing_user_id">{uctrans("client_treatment.fields.default_status")}</label>
									<Select
										disabled={!can("client_treatment.client_treatment", "update")}
										isClearable
										value={participantStatuses.filter(
											({ value }) => Number(value) === Number(formData.default_post_status),
										)}
										options={participantStatuses}
										name="default_post_status"
										onChange={selectedValue =>
											handleFormValueChange("default_post_status", selectedValue ? selectedValue.value : null)
										}
										placeholder={uctrans("general.select_:item", {}, { item: "client_treatment.fields.status" })}
									/>
								</div>
							</div>

							<FormDivider label={uctrans("client_treatment.fields.registration")} />

							<div className="form-1-3">
								<label htmlFor="end">
									{uctrans("client_treatment.fields.registration_start")}
									<span className="text-orange"> *</span>
								</label>
								<DatePicker
									initialValue={formData.registration_start ? parse(formData.registration_start) : null}
									onChange={date => handleFormValueChange("registration_start", date ? format(date) : null)}
									disabled={!can("client_treatment.client_treatment", "update")}
								/>
							</div>

							<div className="form-1-3">
								<label htmlFor="registration_reminder">
									{uctrans("client_treatment.fields.registration_reminder")}
								</label>
								<DatePicker
									disabled={!can("client_treatment.client_treatment", "update")}
									initialValue={formData.registration_reminder ? parse(formData.registration_reminder) : null}
									onChange={date => handleFormValueChange("registration_reminder", date ? format(date) : null)}
								/>
							</div>

							<div className="form-1-3">
								<label htmlFor="registration_end">
									{uctrans("client_treatment.fields.registration_end")}
									<span className="text-orange"> *</span>
								</label>
								<DatePicker
									initialValue={formData.registration_end ? parse(formData.registration_end) : null}
									onChange={date => handleFormValueChange("registration_end", date ? format(date) : null)}
									disabled={!can("client_treatment.client_treatment", "update")}
								/>
							</div>

							<FormDivider label={uctrans("client_treatment.fields.amount_and_costs")} />

							<div className="form-full">
								<div className="form-1-6">
									<label htmlFor="min_amount">{uctrans("client_treatment.fields.minimum_amount")}</label>
									<input
										type="number"
										name="min_amount"
										value={formData.min_amount}
										onChange={e => handleFormValueChange(e.target.name, e.target.value)}
										disabled={!can("client_treatment.client_treatment", "update")}
									/>
								</div>

								<div className="form-1-6">
									<label htmlFor="max_amount">{uctrans("client_treatment.fields.maximum_amount")}</label>
									<input
										type="number"
										name="max_amount"
										value={formData.max_amount}
										onChange={e => handleFormValueChange(e.target.name, e.target.value)}
										disabled={!can("client_treatment.client_treatment", "update")}
									/>
								</div>

								<div className="form-1-6">
									<label htmlFor="costs_per_unit">
										{uctrans("client_treatment.fields.costs_per_unit")} <span className="text-orange"> *</span>
									</label>
									<input
										type="number"
										name="costs_per_unit"
										value={formData.costs_per_unit}
										onChange={e => handleFormValueChange(e.target.name, e.target.value)}
										disabled={!can("client_treatment.client_treatment", "update")}
									/>
								</div>

								<div className="form-1-6">
									<label htmlFor="total_costs_per_unit">
										{uctrans("client_treatment.fields.total_costs_per_unit")} <span className="text-orange"> *</span>
									</label>
									<input
										type="number"
										name="total_costs_per_unit"
										value={formData.total_costs_per_unit}
										onChange={e => handleFormValueChange(e.target.name, e.target.value)}
										disabled={!can("client_treatment.client_treatment", "update")}
									/>
								</div>
							</div>

							<div className="form-full">
								<div className="form-1-6">
									<label htmlFor="invoice_amount">{uctrans("client_treatment.fields.invoice_amount")}</label>
									<input
										type="number"
										name="invoice_amount"
										value={formData.invoice_amount}
										onChange={e => handleFormValueChange(e.target.name, e.target.value)}
										disabled={!can("client_treatment.client_treatment", "update")}
									/>
								</div>

								<div className="form-2-3">
									<label>{uctrans("client_treatment.fields.invoice_file")}</label>
									<FileInput
										initialFiles={formData.invoices}
										onChange={files => handleFormValueChange("invoices", files)}
										existingFileDownloadRoute="crm.client-treatments.download-client-treatment-attachment"
										disabled={!can("client_treatment.client_treatment", "update")}
										maxFiles={10}
										public
									/>
								</div>
							</div>

							<div className="form-full">
								<div className="form-1-4">
									<Checkmark
										onChange={e => handleFormValueChange(e.target.name, e.target.checked)}
										name="iv_budget"
										checked={formData.iv_budget}
										label={uctrans("client_treatment.fields.iv_budget")}
										disabled={!can("client_treatment.client_treatment", "update")}
									/>
								</div>
							</div>

							{Number(formData.type) === Number(staticData.client_treatment_types.EVENT.key) ? (
								<>
									<FormDivider label={uctrans("client_treatment.fields.app")} />

									<div className="form-full">
										<Checkmark
											onChange={e => handleFormValueChange(e.target.name, e.target.checked)}
											name="different_app_description"
											checked={formData.different_app_description}
											label={uctrans("client_treatment.fields.different_app_description")}
											disabled={!can("client_treatment.client_treatment", "update")}
										/>
									</div>

									{formData.different_app_description && (
										<div className="form-full">
											<LabeledTextarea
												required
												label="client_treatment.fields.app_description"
												value={formData.app_description}
												name="app_description"
												translateLabel
												onChange={e => handleFormValueChange(e.target.name, e.target.value)}
												disabled={!can("client_treatment.client_treatment", "update")}
											/>
										</div>
									)}

									<div className="form-full">
										<label>{uctrans("client_treatment.fields.app_photo")}</label>

										<CopyImageSelector
											imageKey="app_photo"
											formData={formData}
											setFormData={setFormData}
											existingFileDownloadRoute="crm.client-treatments.download-client-treatment-attachment"
											disabled={!can("client_treatment.client_treatment", "update")}
										/>
									</div>

									<div className="form-full">
										<label>{uctrans("client_treatment.fields.app_programm")}</label>
										<TextEditor
											value={formData.app_programm}
											onChange={value => handleFormValueChange("app_programm", value)}
											disabled={!can("client_treatment.client_treatment", "update")}
										/>
									</div>

									<div className="form-full">
										<label>{uctrans("client_treatment.fields.locations")}</label>
										<ClientTreatmentLocations
											locations={formData.locations || []}
											onChange={locations => handleFormValueChange("locations", locations)}
											disabled={!can("client_treatment.client_treatment", "update")}
										/>
									</div>

									<div className="form-full">
										<label>{uctrans("client_treatment.fields.app_map")}</label>
										<FileInput
											initialFiles={formData.app_map}
											onChange={files => handleFormValueChange("app_map", files)}
											existingFileDownloadRoute="crm.client-treatments.download-client-treatment-attachment"
											disabled={!can("client_treatment.client_treatment", "update")}
											public
										/>
									</div>

									<div className="form-full">
										<div className="form-1-2">
											<Checkmark
												onChange={e => handleFormValueChange(e.target.name, e.target.checked)}
												name="app_participants_visible"
												checked={formData.app_participants_visible}
												label={uctrans("client_treatment.fields.app_participants_visible")}
												disabled={!can("client_treatment.client_treatment", "update")}
											/>
										</div>
									</div>
								</>
							) : null}

							<FormDivider label={uctrans("client_treatment.fields.dividation")} />

							{!!landingPageAvailable && (
								<div className="form-full">
									<Checkmark
										disabled={!can("client_treatment.client_treatment", "update")}
										name="self_enrollment"
										checked={formData.self_enrollment}
										onChange={e => handleFormValueChange(e.target.name, e.target.checked)}
										label={`${uctrans("client_treatment.fields.self_enrollment")} (${trans(
											"client_treatment.fields.by_landing_page",
										)})`}
									/>
								</div>
							)}

							<div className="form-full">
								<label htmlFor="invite_type">{uctrans("client_treatment.fields.invite_type")}</label>
								<RadioGroup
									name="invite_type"
									disabled={!can("client_treatment.client_treatment", "update")}
									value={formData.invite_type}
									onChange={value => handleFormValueChange("invite_type", value)}>
									<Radio label={uctrans("general.yes")} value={1} />
									<Radio label={uctrans("general.no")} value={0} />
								</RadioGroup>
							</div>
							<div className="form-full">
								<label>{uctrans("client_treatment.fields.divide_for")}</label>
								<ClientTreatmentInvitersSelector
									inviters={formData.inviters}
									freeToInvite={formData.invite_type}
									users={staticData.organisators}
									maxAmount={formData.max_amount}
									onChange={value => handleFormValueChange("inviters", value)}
									disabled={!can("client_treatment.client_treatment", "update")}
								/>
							</div>

							<FormDivider label={uctrans("client_treatment.fields.presentations")} />

							<div className="form-full">
								<label>{uctrans("client_treatment.fields.presentations")}</label>
								<FileInput
									initialFiles={formData.presentations}
									onChange={files => handleFormValueChange("presentations", files)}
									existingFileDownloadRoute="crm.client-treatments.download-client-treatment-attachment"
									disabled={!can("client_treatment.client_treatment", "update")}
								/>
							</div>

							<div className="form-full">
								<div className="row">
									<div className="w-full md:w-1/2 lg:w-1/3 column">
										{can("client_treatment.client_treatment", "update") && (
											<SubmitBar item="client_treatment.singular" addAnotherAvailable={false} />
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="flex-none w-1/3 border-l pl-12">
						{!creating && (
							<>
								<CollapsibleContent title={uctrans("client_treatment.fields.dividation")} initialCollapseState={false}>
									<ClientTreatmentDividationsSum dividationData={staticData.client_treatment_dividations_data} />
								</CollapsibleContent>
								{!!formData.self_enrollment && (
									<CollapsibleContent
										title={uctrans("client_treatment.fields.landing_page")}
										initialCollapseState={false}>
										<div className="mt-3">
											<p>{`${uctrans("client_treatment.fields.non_personal_landing_page_url")}:`}</p>
											<a
												className="link"
												href={route("website.client-treatment-landing.index", {
													client_treatment_id_token: formData.id_token,
												})}>
												{route("website.client-treatment-landing.index", {
													client_treatment_id_token: formData.id_token,
												})}
											</a>
										</div>
									</CollapsibleContent>
								)}
								{!!staticData.client_treatment_related_newsletters &&
									!!staticData.client_treatment_related_newsletters.length && (
										<CollapsibleContent
											title={uctrans("client_treatment.fields.related_newsletters")}
											initialCollapseState={false}>
											<ul>
												{staticData.client_treatment_related_newsletters.map(newsletter => (
													<li key={newsletter.id}>
														<a className="link" href={route("crm.communication.newsletters.edit", newsletter.id)}>
															{newsletter.title}
														</a>
													</li>
												))}
											</ul>
										</CollapsibleContent>
									)}
							</>
						)}
					</div>
				</div>
			</AjaxForm>
		</>
	);
}
ClientTreatmentForm.propTypes = {
	ClietTreatmentFormData: PropTypes.object,
	ClietTreatmentStaticData: PropTypes.object,
};
