import { createLoader } from "../../../../../../js/react/components/general/notifications";
import axios from "axios";
import PropTypes from "prop-types";
import { useCallback, useContext, useState } from "react";
import { route } from "../../../../../../js/helpers";
import { uctrans } from "../../../../../../js/lib/Translator";
import DeleteDialog from "../../../../../../js/react/components/general/DeleteDialog";
import EvaIcon from "../../../../../../js/react/components/general/EvaIcon";
import MediaLibrary from "./MediaLibrary";
import MediaLibraryContext from "./MediaLibraryContext";
import MediaLibraryMediaDialog from "./MediaLibraryMediaDialog";
import MediaLibraryPictureLoader from "./MediaLibraryPictureLoader";

export default function MediaLibraryPicture({ image }) {
	const { mode, refreshMedia, selected, onMediaClick } = useContext(MediaLibraryContext);

	const [hovering, setHovering] = useState(false);
	const [editing, setEditing] = useState(false);
	const [deleteWarnings, setDeleteWarnings] = useState(null);
	const [deleting, setDeleting] = useState(false);

	const startDelete = useCallback(async () => {
		if (deleteWarnings === null) {
			// Retrieve warnings
			try {
				const response = await axios.get(route(`crm.image.delete-check`), { params: { ids: [image.id] } });
				setDeleteWarnings(response.data);
			} catch (error) {
				console.error(error);
				setDeleteWarnings([]);
			}
		}

		setDeleting(true);
	}, [image.id, deleteWarnings, setDeleteWarnings, setDeleting]);

	const handleDelete = useCallback(async () => {
		setDeleting(false);
		const loader = createLoader(uctrans("general.:item_is_being_deleted", {}, { item: "media.image.singular" }));
		try {
			await axios.delete(route(`crm.image.destroy`, image.id));
			loader.success(uctrans("general.deleted_:item", {}, { item: "media.image.singular" }));
			refreshMedia();
		} catch (error) {
			console.error(error);
			loader.failure(error.message);
		}
	}, [image.id, refreshMedia, setDeleting]);

	return (
		<div
			className="pr-8 pt-8 w-1/3 max-h-fit-content"
			onMouseEnter={() => setHovering(true)}
			onMouseLeave={() => setHovering(false)}>
			<div
				onClick={() => onMediaClick(image)}
				className={`relative h-48 cursor-pointer ${
					selected.indexOf(image.id) !== -1 ? "border-2 border-primary shadow" : "border border-grey"
				}`}>
				<div className="h-full w-full text-center">
					{hovering && (
						<span className="absolute top-0 inset-x-0 bg-grey-darker text-white text-center p-4 opacity-75 truncate z-10">
							{image.name}
						</span>
					)}
					<MediaLibraryPictureLoader image={image} overflow={mode !== MediaLibrary.MODE_LIBRARY} />
				</div>
				{selected.indexOf(image.id) !== -1 && (
					<span
						onClick={() => onMediaClick(image)}
						className="absolute top-0 left-0 bg-primary p-1 pb-0 cursor-pointer">
						<EvaIcon type="checkmark" width="24" height="24" fill="white" />
					</span>
				)}
				{hovering && mode === MediaLibrary.MODE_LIBRARY && (
					<>
						<a
							href={image.download_url}
							target="_blank"
							onClick={e => e.stopPropagation()}
							className="absolute bottom-0 left-0 bg-grey-darker p-1 pb-0 opacity-75 cursor-pointer"
							rel="noreferrer">
							<EvaIcon type="download-outline" width="24" height="24" fill="white" />
						</a>
						<span
							onClick={e => {
								e.stopPropagation();
								setEditing(true);
							}}
							className="absolute bottom-0 bg-grey-darker p-1 pb-0 opacity-75 cursor-pointer"
							style={{ right: "35px" }}>
							<EvaIcon type="edit-outline" width="24" height="24" fill="white" />
						</span>
						<span
							onClick={e => {
								e.stopPropagation();
								startDelete();
							}}
							className="absolute right-0 bottom-0 bg-grey-darker p-1 pb-0 opacity-75 cursor-pointer">
							<EvaIcon type="trash-2-outline" width="24" height="24" fill="white" />
						</span>
					</>
				)}
			</div>

			{mode === MediaLibrary.MODE_LIBRARY && (
				<>
					<MediaLibraryMediaDialog
						isOpen={editing}
						media={image}
						onCancel={() => setEditing(false)}
						onConfirm={() => {
							setEditing(false);
							refreshMedia();
						}}
					/>

					<DeleteDialog
						isOpen={deleting}
						onCancel={() => setDeleting(false)}
						onConfirm={handleDelete}
						deleteData={{
							message: uctrans(
								"general.confirm_delete_:item_:label",
								{ label: image.name },
								{ item: "media.image.singular" },
							),
							errors: [],
							warnings: deleteWarnings || [],
						}}
					/>
				</>
			)}
		</div>
	);
}

MediaLibraryPicture.propTypes = {
	image: PropTypes.object.isRequired,
};
